@import "https://fonts.googleapis.com/css?family=Open+Sans|Open+Sans+Condensed:300,700|Pacifico";

/*@media screen and (max-width: 768px) {*/
/*    .none {*/
/*        display: none !important;*/
/*    }*/
/*}*/


body {
    padding-top: 54px;
    width: 100%;
    background: #f6f8fa;
    color: #000000d9;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    font-size: 14px;
    font-weight: 500;
    scrollbar-color: #8ab5ff #e6efff;
    scrollbar-width: 8px;
    overflow-x: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #e6efff;
    border-radius: 100px;
}
::-webkit-scrollbar-thumb {
    background: #8ab5ff;
    border-radius: 100px;
    border: 3px solid #e6efff;
}

.bg-dark {
    background-color: #122738 !important;
}

.pacificoFont270 {
    font-family: "Pacifico", Arial, sans-serif;
    /*writing-mode: vertical-rl;*/
    right: -57px;
    top: 50px;
    position: absolute;
    transform: rotate(270deg);
    color: indianred;
    text-shadow: 0px 0px 6px white, 0px 0px 0px #ff0081, -3px 6px 10px #ff0e0e, 0px 0px 8px #ffffff;
    /*!*position: fixed;*!*/
    /*z-index: 0;*/
    /*transform-origin: top right;*/
    /*bottom: 0%;*/
    /*right: calc(50% - 391px);*/
    /*transform: rotate(270deg) translateX(-42%) translateY(-1%);*/
}

.pacificoFont {
    font-family: "Pacifico", Arial, sans-serif;
}

.text-truncate3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /*-webkit-line-clamp: 3;*/
    /*line-clamp: 3;*/
    -webkit-box-orient: vertical;
}

.text-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.article {
    margin: auto;
    position: relative;
    top: 100px;
    color: #1e3e3f;
    padding: 20px;
    font-size: 15px;
}

.date {
    color: #5c6b72;
    display: inline-block;
    margin-right: 16px;
}

.icon {
    margin-right: 5px;
    color: #5c6b72;
}

.fa-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}

.home-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.fake-home-background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nav-link.active {
    color: red !important;
    background-color: transparent !important;
    border-color: blue !important;
    /*opacity: 0; 透明*/
}


/*#home-head .home-info {*/
/*    position: relative;*/
/*    margin: auto;*/
/*}*/
.home-head {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    height: calc(100vh + 50px);
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    left: 0;
}

/*.info {*/
/*    position: absolute;*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -moz-box;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    text-align: center;*/
/*    color: #333;*/
/*    border-radius: 50%;*/
/*}*/

.loop {
    position: absolute;
    display: inline-block;
}

.home-info {
    position: relative;
    margin: auto;
}

.info {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333;
    border-radius: 50%;
    margin: auto;
    /*background: rgba(255, 255, 255, 0.651);*/
}

.wrap {
    padding: 25px;
}
.wraph1 {
    font-weight: bold;
    font-family: "Pacifico", Arial, sans-serif;
    margin-bottom: 10px;
}


@-moz-keyframes loop1 {
    0% {
        -moz-transform: rotate(30deg);
    }
    100% {
        -moz-transform: rotate(390deg);
    }
}

@-webkit-keyframes loop1 {
    0% {
        -webkit-transform: rotate(30deg);
    }
    100% {
        -webkit-transform: rotate(390deg);
    }
}

@-o-keyframes loop1 {
    0% {
        -o-transform: rotate(30deg);
    }
    100% {
        -o-transform: rotate(390deg);
    }
}

@keyframes loop1 {
    0% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(390deg);
    }
}

@-moz-keyframes loop2 {
    0% {
        -moz-transform: rotate(60deg);
    }
    100% {
        -moz-transform: rotate(420deg);
    }
}

@-webkit-keyframes loop2 {
    0% {
        -webkit-transform: rotate(60deg);
    }
    100% {
        -webkit-transform: rotate(420deg);
    }
}

@-o-keyframes loop2 {
    0% {
        -o-transform: rotate(60deg);
    }
    100% {
        -o-transform: rotate(420deg);
    }
}

@keyframes loop2 {
    0% {
        transform: rotate(60deg);
    }
    100% {
        transform: rotate(420deg);
    }
}

@-moz-keyframes loop3 {
    0% {
        -moz-transform: rotate(90deg);
    }
    100% {
        -moz-transform: rotate(450deg);
    }
}

@-webkit-keyframes loop3 {
    0% {
        -webkit-transform: rotate(90deg);
    }
    100% {
        -webkit-transform: rotate(450deg);
    }
}

@-o-keyframes loop3 {
    0% {
        -o-transform: rotate(90deg);
    }
    100% {
        -o-transform: rotate(450deg);
    }
}

@keyframes loop3 {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(450deg);
    }
}

@-moz-keyframes loop4 {
    0% {
        -moz-transform: rotate(120deg);
    }
    100% {
        -moz-transform: rotate(480deg);
    }
}

@-webkit-keyframes loop4 {
    0% {
        -webkit-transform: rotate(120deg);
    }
    100% {
        -webkit-transform: rotate(480deg);
    }
}

@-o-keyframes loop4 {
    0% {
        -o-transform: rotate(120deg);
    }
    100% {
        -o-transform: rotate(480deg);
    }
}

@keyframes loop4 {
    0% {
        transform: rotate(120deg);
    }
    100% {
        transform: rotate(480deg);
    }
}

.loop:nth-child(1) {
    border-radius: 38% 62% 63% 37%/41% 44% 56% 59%;
    background: #fff;
    opacity: 0.3;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation: loop1 10s linear infinite;
    -moz-animation: loop1 10s linear infinite;
    -o-animation: loop1 10s linear infinite;
    animation: loop1 10s linear infinite;
}

.loop:nth-child(2) {
    border-radius: 38% 62% 63% 37%/41% 44% 56% 59%;
    background: #fff;
    opacity: 0.45;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation: loop2 15s linear infinite;
    -moz-animation: loop2 15s linear infinite;
    -o-animation: loop2 15s linear infinite;
    animation: loop2 15s linear infinite;
}

.loop:nth-child(3) {
    border-radius: 38% 62% 63% 37%/41% 44% 56% 59%;
    opacity: 0.3;
    background: #fff;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: loop3 10s linear infinite;
    -moz-animation: loop3 10s linear infinite;
    -o-animation: loop3 10s linear infinite;
    animation: loop3 10s linear infinite;
}

.loop:nth-child(4) {
    border-radius: 38% 62% 63% 37%/41% 44% 56% 59%;
    opacity: 0.45;
    background: #fff;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation: loop4 15s linear infinite;
    -moz-animation: loop4 15s linear infinite;
    -o-animation: loop4 15s linear infinite;
    animation: loop4 15s linear infinite;
}


.mdPre{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebeef5;
    margin: 25px 0;
    border-radius: 15px;
    position: relative;
}
.mdCodeContent {
    overflow: auto;
    padding: 50px 30px 20px;
}
.mdLanguage {
    position: absolute;
    top: 0px;
    left: 30px;
    padding: 10px 15px;
    background: -webkit-linear-gradient(to right, #ed6ea0 0, #ec8c69 100%);
    background: -moz-linear-gradient(to right, #ed6ea0 0, #ec8c69 100%);
    background: -o-linear-gradient(to right, #ed6ea0 0, #ec8c69 100%);
    background: linear-gradient(to right, #ed6ea0 0, #ec8c69 100%);
    box-shadow: 1px 1px 0.75rem rgba(237, 110, 161, 0.302);
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    border-radius: 0 0 10px 10px;
}
.mdH1{
    color: #f76c6c;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    text-align: left !important;
    margin: 18px 0;
}
.mdH2{
    font-weight: bold;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    color: #1e3e3f;
    font-size: 27px;
    text-align: left !important;
    margin: 18px 0;
}
.mdh3 {
    font-weight: bold;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    color: #1e3e3f;
    font-size: 24px;
    text-align: left !important;
    margin: 18px 0;
}
.mdh4 {
    font-weight: bold;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    color: #1e3e3f;
    font-size: 21px;
    text-align: left !important;
    margin: 18px 0;
}
.mdh5 {
    font-weight: bold;
    font-family: "Poppins", "Noto Sans SC", sans-serif;
    color: #1e3e3f;
    font-size: 18px;
    text-align: left !important;
    margin: 18px 0;
}
/*----------------------------------test*/
.guide-container {
    position: relative;
    border: 1px solid #f00;
    margin: 10px 0;
    height: 70px;
    width: 50%;
}

.guide-extras {
    position: absolute;
    top: 0px;
    /*right: -210px;*/
    /*width: 200px;*/
    /*border: 1px dashed #00f;*/
    display: none;
}

.guide-container:hover .guide-extras {
    display: block;
}
/*----------------------------------test*/


